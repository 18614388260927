import React, { useLayoutEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { IconButton, Box, Grid } from '@mui/material';
import { Close, MailOutline, PersonOutline } from '@mui/icons-material';
import { gsap } from 'gsap';

import { logout } from '../actions/user';

import head from '../assets/images/head.png'

export default function FullScreenMenu ({open, setOpen}) {  
    const artRef = useRef(null);
    const links = [ { label: "ABOUT", to:"/#about" }, { label: "WORK", to:"/#work" }, { label: "CONTACT", to:"/#contact" }];  
    const userLogin = useSelector((state) => state.userLogin);
    const { user } = userLogin;
    const dispatch = useDispatch();
    const logoutHandler = () => {
        dispatch(logout());
        setOpen(!open)
    };
    useLayoutEffect(() => {
        gsap.fromTo(artRef.current, { repeatDelay: 0, },
        {
            rotation: 360,
            transformOrigin: "center center",
            duration: 10,
            repeat: -1,
            ease: "none",
        })
    });
    return (
        <Box flexGrow={1} sx={{
            position: "fixed",
            left: 0,
            top: 0,
            zIndex: 5,
            minHeight: "100%",
            width:"100%",
            transition: open ? "all 0.5s ease 0s" : "all 0.5s ease 0s;",
            transform: open ? "translate3d(0px, 0px, 0px);" : "translate3d(100%, 0px, 0px);",
        }}>
            <Grid container sx={{
                backgroundColor: "black",
                color: "white",
                overflow: "scroll",
                height: "100vh",
                py: 1
            }}>
                <Grid container sx={{ height:"auto", borderBottom: 2, borderColor: "white", px: 4 }}> 
                    <Grid item xs={12} sx={{ textAlign: "right", alignItems: "center" }}>
                        <IconButton
                                edge="end"
                                aria-label="close"
                                disableRipple={true}
                                onClick={() => setOpen(!open)}
                            >
                            <Close sx={{ color: "white", fontSize: "30px"}}/>
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container sx={{ height:"92%" }}>
                    <Grid item sm={12} md={9} className="nav-right">
                        <Grid container>
                            <Grid item xs={12} sx={{ borderBottom: 2, borderColor: "white", px: 4 }}>
                            {links.map((item, index) => (
                                <Link
                                    to={item.to}
                                    onClick={() => setOpen(!open)}
                                    key={index}
                                    className="nav-item"
                                >
                                    <h1>{index+1>9 ? index+1: `0${index+1}`}. {item.label}</h1>
                                </Link>
                            ))}
                            </Grid>
                            <Grid item xs={12} sx={{ py: 3, px: 4 }}>
                                <Grid container columnSpacing={4} rowSpacing={2}>
                                    <Grid item sm={12} md={6}>
                                        <h3>Connect</h3>
                                        <Box onClick={() => window.location = 'mailto:hello@mathenaangeles.com'} sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            mt: 2,
                                            cursor: "pointer"
                                        }}>
                                            <MailOutline sx={{ fontSize: "26px", pr: 1 }}/>
                                            hello@mathenaangeles.com
                                        </Box>
                                        <Box sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            mt: 2
                                        }}>
                                            <PersonOutline sx={{ fontSize: "26px", pr: 1 }}/>
                                            <a href="https://discord.com/users/Mathena#5483" target="_blank" rel="noreferrer" className="social-link">mathenaangeles</a>
                                        </Box>
                                    </Grid>
                                    <Grid item sm={12} md={6}>
                                        <h3>Get in touch —</h3>
                                        <p>for my availability. We can schedule a consultation as needed.</p>
                                        { user &&
                                            <Link to="#logout" onClick={logoutHandler} sx={{ mr: 2 }}>
                                                <b style={{ color: "white", borderBottom: "2px solid white" }}>Click here to logout.</b>
                                            </Link> 
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={12} md={3}>
                        <Grid container  sx={{ px: 4, py: 2 }}>
                            <Grid item xs={12}>
                                <h3>Socials</h3>
                                <p><a href="https://www.instagram.com/mathenaangeles/" target="_blank" rel="noreferrer" className="social-link">Instagram</a></p>
                                <p><a href="https://www.linkedin.com/in/mathenaangeles" target="_blank" rel="noreferrer" className="social-link">LinkedIn</a></p>
                                <p><a href="https://github.com/mathenaangeles" target="_blank" rel="noreferrer" className="social-link">GitHub</a></p>
                                { user &&
                                    <Link to={`/projects`} onClick={() => setOpen(!open)} sx={{ mr: 2 }}>
                                        <b style={{ color: "white", borderBottom: "2px solid white", marginBottom: "100px" }}>Click here to see projects.</b>
                                    </Link> 
                                }
                            </Grid>
                            <Grid container sx={{ position:"relative" }}>
                                <a href="https://www.instagram.com/mathenaangeles/" target="_blank" rel="noreferrer" className="social-link">
                                    <svg ref={artRef} viewBox="0 0 200 200" width="200" height="200" style={{ position:"relative", left: "20px" }}>
                                        <defs>
                                        <path id="circle"
                                            d="
                                            M 90, 90
                                            m -65, 0
                                            a 65,65 0 1,1 130,0
                                            a 65,65 0 1,1 -130,0"
                                        />
                                        </defs>
                                        <text fontSize="20.5" fill="#ffffff" style={{ fontWeight: "bold" }}>
                                        <textPath href="#circle">
                                            Art & Photography  ●  Art & Photography  ● 
                                        </textPath>
                                        </text>
                                    </svg>
                                    <img style={{ position:"absolute", top:"50px" }} alt="fragmentary colossal head of youth" src={head}/>
                                </a>
                            </Grid>
                            <Grid item xs={12} mt={10}>
                                <p>Mathena Angeles © {new Date().getFullYear()}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}